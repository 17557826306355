import { render, staticRenderFns } from "./CustomerCareLink.vue?vue&type=template&id=e9ae7318&scoped=true&"
import script from "./CustomerCareLink.vue?vue&type=script&lang=js&"
export * from "./CustomerCareLink.vue?vue&type=script&lang=js&"
import style0 from "./CustomerCareLink.vue?vue&type=style&index=0&id=e9ae7318&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9ae7318",
  null
  
)

component.options.__file = "CustomerCareLink.vue"
export default component.exports